import React from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

export default function HeroStyle5({
  title,
  subTitle,
  bgUrl,
  imgUrl,
  videoBtnText,
  videoUrl,
  funfactList,
  btnText,
  btnUrl,
}) {
  return (
    <section className="billboardo">
        <img src={imgUrl} alt="Hero" />
  
    </section>
  );
}
