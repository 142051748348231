import React from 'react';
import BannerSectionStyle5 from '../Section/BannerSection/BannerSectionStyle5';
import BannerSectionStyle4 from '../Section/BannerSection/BannerSectionStyle4';
import TeamSectionStyle2 from '../Section/TeamSection/TeamSectionStyle2';
import Section from '../Section';
import { pageTitle } from '../../helpers/PageTitle';
const teamData = [
  {
    imgUrl: '/images/home_1/dr.png',
    department: 'Paediatrician',
    name: 'Dr. Kulani Mabasa, MD',
    designation: 'Specialist Paediatrician',
    description: 'Dr. Kulani Mabasa is a dedicated specialist paediatrician committed to providing compassionate and expert care to children. With extensive experience and qualifications in paediatrics, Dr. Mabasa offers comprehensive medical services tailored to meet the unique needs of each child.',
    social: [
      { icon: 'fa6-brands:facebook-f', href: '/drkulanimabasa' },
      { icon: 'fa6-brands:linkedin-in', href: '/drkulanimabasa' },
      { icon: 'fa6-brands:twitter', href: '/drkulanimabasa' },
    ],
    category: 'paediatrics',
    href: '/doctors/doctor-details',
  }
  ]

export default function Doctors() {
  pageTitle('Doctors');
  return (
    <>
      <BannerSectionStyle5
        bgUrl="/images/doctors/banner_bg.svg"
        imgUrl="/images/home_1/hero_img.png"
        title="Specialist Paediatrician"
        subTitle="Have peace of mind while we care for your  <br />child's health"
      />
      <Section topMd={65} bottomMd={200} bottomLg={150} bottomXl={110}>
        <TeamSectionStyle2 data={teamData} />
      </Section>
      <Section className="cs_footer_margin_0">
        <BannerSectionStyle4
          bgUrl="images/doctors/banner_bg_22.png"
          title="Don’t Let Your Health <br />Take a Backseat!"
          subTitle="Have peace of mind while we care for your <br>
child's health <br />"
        />
      </Section>
    </>
  );
}
