import React from 'react';
import TextWidget from '../Widget/TextWidget';
import ContactInfoWidget from '../Widget/ContactInfoWidget';
import SocialWidget from '../Widget/SocialWidget';
import MenuWidget from '../Widget/MenuWidget';
import NewsletterStyle4 from '../Widget/NewsletterStyle4';
const menuDataOne = [
  { title: 'About Us', href: '/about' },
 
  { title: 'Doctors', href: '/doctors' },

  { title: 'Appointment', href: '/appointments' },
];

const menuDataTwo = [
  { title: 'Testimonials', href: '/' },

  { title: 'Contact Us', href: '/' },
  { title: 'FAQs', href: '/' },
];
const menuDataThree = [
  { title: 'Privacy Policy', href: 'https://firebasestorage.googleapis.com/v0/b/saharasculptors-web.appspot.com/o/privacy%20policy.txt?alt=media&token=10a77d54-fe94-4614-b103-c46c991d9678' },
  { title: 'Terms and Conditions', href: 'https://firebasestorage.googleapis.com/v0/b/saharasculptors-web.appspot.com/o/terms%20and%20conditions.txt?alt=media&token=45f97df2-c416-4058-b23c-cd1c2af44815' },
];

export default function FooterStyle4() {
  return (
    <footer className="cs_footer cs_style_2 cs_type_1 cs_accent_bg cs_white_color">
      <div className="container">
  
        <div className="cs_footer_in">
          <div className="cs_footer_col">
            <div className="cs_footer_item">
              <TextWidget
                logoUrl="/images/footer_logo_white.svg"
                text="Dr Kulani Mabasa Paediatric Clinics"
              />
              <ContactInfoWidget />
            </div>
          </div>
          <div className="cs_footer_col">
            <div className="cs_footer_item">
              <MenuWidget data={menuDataOne} />
            </div>
          </div>
          <div className="cs_footer_col">
            <div className="cs_footer_item">
              <MenuWidget data={menuDataTwo} />
            </div>
          </div>
          <div className="cs_footer_col">
            <div className="cs_footer_item">
              <MenuWidget data={menuDataThree} />
              <SocialWidget />
            </div>
          </div>
        </div>
      </div>
      <div className="cs_footer_bottom">
        <div className="container">
          <div className="cs_copyright" href='www.drkulanimabasa.co.za'>
            Copyright © 2024 Dr Kulani Mabasa . All rights reserved.Developed by SaharaSculptors.co.za
          </div>
        </div>
      </div>
    </footer>
  );
}
