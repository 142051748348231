import React, { useState } from 'react';
import emailjs from 'emailjs-com'; // Import emailjs library

function SignUpForm() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: ''
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Form validation
    if (!formData.firstName || !formData.lastName || !formData.phoneNumber || !formData.email) {
      alert("Please fill out all fields");
      return;
    }
  
    setIsLoading(true); // Set loading state to true
  
    try {
      const emailResult = await emailjs.send('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', {
        firstName: formData.firstName,
        lastName: formData.lastName,
        phoneNumber: formData.phoneNumber,
        email: formData.email
      }, 'YOUR_USER_ID');
      alert('Thank you for signing up for affiliation. Sahara team will be in touch with you!');
      // Reset form fields
      setFormData({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: ''
      });
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Failed to sign up. Please try again later.');
    } finally {
      setIsLoading(false); // Set loading state back to false
    }
  };
  
  return (
    <div className="signup-container">
      <h1>Sign Up for Affiliation</h1>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <input type="text" name="firstName" placeholder="First Name" value={formData.firstName} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <input type="text" name="lastName" placeholder="Last Name" value={formData.lastName} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <input type="tel" name="phoneNumber" placeholder="Phone Number" value={formData.phoneNumber} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} required />
        </div>
        <button type="submit" disabled={isLoading}>Sign Up</button>
      </form>
      {isLoading && <p>Loading...</p>}
    </div>
  );
}

export default SignUpForm;
