import React from 'react';
import BreadcrumbStyle2 from '../Breadcrumb/BreadcrumbStyle2';
import Section from '../Section';
import BannerSectionStyle9 from '../Section/BannerSection/BannerSectionStyle9';
import DoctorDetailsSection from '../Section/DoctorDetailsSection';
import AppointmentSectionStyle2 from '../Section/AppointmentSection/AppointmentSectionStyle2';
import { pageTitle } from '../../helpers/PageTitle';

export default function DoctorDetails() {
  pageTitle('Doctor Details');
  return (
    <>
      <BreadcrumbStyle2 />
      <Section bottomMd={190} bottomLg={150} bottomXl={110}>
        <DoctorDetailsSection
         
          imgUrl="/images/home_1/dr2.png"
          name="Dr. Kulani Mabasa, MD"
          department="Paediatrician"
          designation="Specialist Paediatrician"
          description="Dr. Kulani Mabasa is a graduate from the University of Witwatersrand where she obtained her MBBCH degree. She served as a medical intern in Helen Joseph/Rahima Moosa Mother and Child Hospitals Complex in Johannesburg and completed her community service in Siloam Hospital in Vhembe district, Limpopo. Dr. Mabasa developed an interest in paediatrics as a medical student, which influenced her choice to work in paediatrics and neonatal wards during community service and as a medical officer in Siloam Hospital. She obtained a Diploma in Child Health through the College of Medicine South Africa. Dr. Mabasa then specialized to become a paediatrician, receiving intensive training in neonatal and paediatric care through various hospitals affiliated with the University of Witwatersrand. She obtained her Fellowship in Paediatrics with the College of Medicine South Africa. Dr. Mabasa obtained her Master's in Medicine through the University of Witwatersrand, with her research based in Chris Hani Baragwanath Neonatal Unit and published in SAJCH with the title: Risk factors for intraventricular haemorrhage in very low birth weight infants at Chris Hani Baragwanath Academic Hospital, Johannesburg, South Africa."
          // social={[
          //   { icon: 'fa6-brands:facebook-f', href: '/drkulanimabasa' },
          //   { icon: 'fa6-brands:linkedin-in', href: '/drkulanimabasa' },
          //   { icon: 'fa6-brands:twitter', href: '/drkulanimabasa' },
          // ]}
          contactInfo={[
            { iconUrl: '/images/icons/call.svg', title: '015 880 2254' },
            {
              iconUrl: '/images/icons/envlope.svg',
              title: 'info@Drkulanimabasa.co.za',
            },
          ]}
          contactInfoHeading="Contact Info"
          schedules={[
            { day: 'Monday', time: '09.00-12.00' },
            { day: 'Wednesday', time: '15.00-18.00' },
            { day: 'Friday', time: '09.00-12.00' },
          ]}
          scheduleHeading="Appointment Schedules"
          degrees={[
            {
              title: 'MBBCH (Wits)',
              subTitle: 'Medical degree',
            },
            {
              title: 'DCH (SA)',
              subTitle: 'Diploma in Child Health',
            },
            {
              title: 'FCPaeds (SA)',
              subTitle: 'Fellow of the College of Paediatrics of South Africa',
            },
            {
              title: 'MMED (Wits)',
              subTitle: 'Master of Medicine in Paediatrics',
            },
          ]}
          degreesHeading="Degrees"
          experiencesHeading="Experiences"
          awardHeading="Awards/Achievements"
        />
      </Section>

      <Section className="cs_footer_margin_0">
        <BannerSectionStyle9
          title="Don’t Let Your Health <br />Take a Backseat!"
          subTitle="Have peace of mind while we care for your <br>child's health <br />"
          imgUrl="/images/home_1/dr2.png"
        />
      </Section>
    </>
  );
}
