import React, { useState, useRef } from 'react';
import emailjs from 'emailjs-com';

export default function ContactForm() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const form = useRef();

  const sendEmail = async (e) => {
    e.preventDefault();

    if (!name || !email || !subject || !message) {
      alert("Please fill out all fields");
      return;
    }

    setIsLoading(true);

    try {
      const emailResult = await emailjs.sendForm('service_yn6q1k9', 'template_dqmwrp3', form.current, '4OUbdFhTlOGAadTys');
      alert('Message Sent: ' + emailResult.text);
      setName('');
      setEmail('');
      setSubject('');
      setMessage('');
    } catch (error) {
      console.error('Failed to send email:', error);
      alert('Failed to send message.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="cs_contact_form cs_style_1 cs_white_bg cs_radius_30">
      <form ref={form} onSubmit={sendEmail} className="row">
        <div className="col-lg-6">
          <label className="cs_input_label cs_heading_color">Name</label>
          <input
            type="text"
            name="name"
            className="cs_form_field"
            placeholder="David John"
            value={name}
            onChange={e => setName(e.target.value)}
          />
          <div className="cs_height_42 cs_height_xl_25" />
        </div>
        <div className="col-lg-6">
          <label className="cs_input_label cs_heading_color">Email</label>
          <input
            type="email"
            name="email"
            className="cs_form_field"
            placeholder="example@gmail.com"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <div className="cs_height_42 cs_height_xl_25" />
        </div>
        <div className="col-lg-12">
          <label className="cs_input_label cs_heading_color">Subject</label>
          <input
            type="text"
            name="subject"
            className="cs_form_field"
            placeholder="Your subject"
            value={subject}
            onChange={e => setSubject(e.target.value)}
          />
          <div className="cs_height_42 cs_height_xl_25" />
        </div>
        <div className="col-lg-12">
          <label className="cs_input_label cs_heading_color">Message</label>
          <textarea
            cols={30}
            rows={10}
            name="message"
            className="cs_form_field"
            placeholder="Write something..."
            value={message}
            onChange={e => setMessage(e.target.value)}
          />
          <div className="cs_height_42 cs_height_xl_25" />
        </div>
        <div className="col-lg-12">
          <button type="submit" className="cs_btn cs_style_1" disabled={isLoading}>
            <span>{isLoading ? 'Sending...' : 'Submit'}</span>
          </button>
        </div>
      </form>
    </div>
  );
}
