import React from 'react';
import HeroStyle5 from '../Hero/HeroStyle5';
import Section from '../Section';
import { pageTitle } from '../../helpers/PageTitle';
import DepartmentSectionStyle2 from '../Section/DepartmentSection/DepartmentSectionStyle2';
import AboutSection from '../Section/AboutSection';
import DepartmentSectionStyle5 from '../Section/DepartmentSection/DepartmentSectionStyle5';
import BannerSectionStyle2 from '../Section/BannerSection/BannerSectionStyle2';
import TeamSection from '../Section/TeamSection';
import AppointmentSectionStyle2 from '../Section/AppointmentSection/AppointmentSectionStyle2';
import FaqSectionStyle2 from '../Section/FaqSection/FaqSectionStyle2';
import BlogSectionStyle4 from '../Section/BlogSection/BlogSectionStyle4';
import BrandsSectionStyle3 from '../Section/BrandsSection/BrandsSectionStyle3';
const departmentData = [
  {
    title: 'Newborn Care',
    subTitle:
      '',
    iconUrl: '/images/icons/calendar_white.svg',
    href: '/departments/department-details',
  },
  {
    "title": "General Paediatrics Consultation",
    "subTitle": "",
    "iconUrl": "/images/icons/calendar_white.svg",
    "href": "/departments2"
  },
  
  {
    title: 'Acute and Chronic Childhood Illnesses Management',
    subTitle:
      '',
    iconUrl: '/images/icons/calendar_white.svg',
    href: '/departments3',
  },
  {
    title: 'Guide on Infant Feeding and Nutritional Education',
    subTitle:
      '',
    iconUrl: '/images/icons/calendar_white.svg',
    href: '/departments4',
  },
  {
    title: 'Vaccinations (Coming Soon)',
    subTitle:
      '',
    iconUrl: '/images/icons/calendar_white.svg',
    href: '/departments5',
  },
  {
    title: 'Outreach Services',
    subTitle:
      '',
    iconUrl: '/images/icons/calendar_white.svg',
    href: '/departments6',
  },
];
const faqData = [
  {
    title: 'What services does Dr. Kulani Mabasa offer?',
    content:
      'Dr. Kulani Mabasa offers a comprehensive range of paediatric services, including newborn care, general paediatrics consultation, management of acute and chronic childhood illnesses, developmental screenings, vaccinations, and nutritional guidance.',
  },
  {
    title: 'How do I schedule an appointment with Dr. Kulani Mabasa?',
    content:
      'To schedule an appointment with Dr. Kulani Mabasa, you can contact our clinic via phone at 015 880 2254 or email us at info@drkulanimabasa.co.za. Our friendly staff will assist you in booking a convenient appointment slot.',
  },
  {
    title: 'Do you accept  medical aids?',
    content:
      'Yes, we accept most  medical aids. However, its recommended to contact your insurance provider beforehand to confirm coverage for paediatric services at our clinic.',
  },
  {
    title: 'What should I bring to my appointment with Dr. Kulani Mabasa?',
    content:
      'For your appointment with Dr. Kulani Mabasa, please bring your  Road to health booklet ( Clinic booklet/card), childs medical records, any relevant test results, insurance information, and a list of any medications your child is currently taking.',
  },
  {
    title: 'How do I request a prescription refill from Dr. Kulani Mabasa?',
    content:
      'To request a prescription refill from Dr. Kulani Mabasa, you can contact our clinic via phone or email. Please provide us with your childs name, the name of the medication, and the pharmacy where youd like the prescription to be sent.',
  },
];

const blogData = [
  {
    title: 'The Benefits of Mindfulness Meditation for Stress and Anxiety',
    thumbUrl: '/images/home_3/blog_1.jpeg',
    date: 'May 21, 2023',
    href: '/blog/blog-details',
    socialShare: true,
  },
  {
    title:
      'Understanding Chronic Fatigue Syndrome: Symptoms, Causes, and Treatment',
    thumbUrl: '/images/home_3/blog_2.jpeg',
    date: 'May 20, 2023',
    href: '/blog/blog-details',
    socialShare: false,
  },
  {
    title: 'The Importance of Regular Cancer Screenings and Early Detection',
    thumbUrl: '/images/home_3/blog_3.jpeg',
    date: 'May 15, 2023',
    href: '/blog/blog-details',
    socialShare: false,
  },
  {
    title:
      'Managing Chronic Pain: Tips and Strategies for a Better Quality of Life',
    thumbUrl: '/images/home_3/blog_4.jpeg',
    date: 'May 10, 2023',
    href: '/blog/blog-details',
    socialShare: false,
  },
];
const brandData = [
  { imgUrl: '/images/home_4/brand_logo_1.svg', imgAlt: 'Brand' },
  { imgUrl: '/images/home_4/brand_logo_2.svg', imgAlt: 'Brand' },
  { imgUrl: '/images/home_4/brand_logo_3.svg', imgAlt: 'Brand' },
  { imgUrl: '/images/home_4/brand_logo_4.svg', imgAlt: 'Brand' },
  { imgUrl: '/images/home_4/brand_logo_5.svg', imgAlt: 'Brand' },
];

export default function HomeStyle5() {
  pageTitle('Clinic');
  return (
    <>
      <HeroStyle5
        title="New Born And Child Health Specialist"
        subTitle="along with our team of experienced doctors and healthcare professionals, is committed to providing quality care and personalized attention to our patients."
        imgUrl="/images/home_5/hero_img223.svg"
        btnText="Dr Kulani Mabasa "
        btnUrl="/about"
        funfactList={[
          { number: '10+', title: 'Years of experience' },
          { number: '95%', title: 'Patient satisfaction rating' },
          { number: '1000+', title: 'Patients served annually' },
          { number: '4+', title: 'Healthcare Services offered' },
        ]}
      />
      <Section topMd={200} topLg={150} topXl={110}>
        <DepartmentSectionStyle2
          sectionTitle="Our Services"
          sectionTitleUp="SERVICES"
          data={departmentData}
        />
      </Section>
      {/* Start About Section */}
      <Section topMd={180} topLg={130} topXl={100}>
        <AboutSection
          imgUrl="/images/home_1/about.png"
     
          title="About"
          subTitle="Dr Kulani Mabasa "
          featureList={[
            {
              featureListTitle:
                'About Dr Kulani Mabasa',
              featureListSubTitle:
                ' is a graduate from university of Witwatersrand where She obtained her MBBCH degree. She served as medical intern in Helen Joseph/Rahima Moosa mother and child hospitals complex Johannesburg. Completed her community service in Siloam Hospital in Vhembe district Limpopo.She developed interest in paediatrics as a medical student which influenced her choice to work in paediatrics and neonatal wards during community service and as medical officer in Siloam Hospital. Is then that she realized a need for further training and education in child health care.Dr Kulani obtained Diploma in child health through college of medicine South Africa.  Then went further to specialize to become a paediatrician. She received intensive training in neonatal and paediatric care through Chris Hani Baragwanath Hospital, Charlotte Maxeke academic hospital,Rahima Moosa Mother and child hospitals and other hospitals affiliated to University of Witwatersrand. She obtained her Fellowship in Paediatrics with College of medicine South Africa. Through the university of Witwatersrand, Dr Kulani obtained masters in medicine. Her research was based in Chris Hani Baragwanath neonatal unit and was published in SAJCH with title: Risk factors for intraventricular haemorrhage in very low birth weight infants at Chris Hani Baragwanath Academic hospital, Johannesburg South Africa.Dr Kulani Mabasa Specialist Paediatrician MBBCH WITS, FC PAEDS(SA), DCH(SA), MMED(WITS)'
            },
          ]}
        />
      </Section>
      {/* End About Section */}
      <Section
        topMd={200}
        topLg={150}
        topXl={110}
        bottomMd={175}
        bottomLg={125}
        bottomXl={85}
      >
      
      </Section>
      <Section>
        <BannerSectionStyle2
          bgUrl="/images/home_5/hero_img22.jpg"
          title="."
          subTitle="."
        />
      </Section>
      <Section
        topMd={190}
        topLg={145}
        topXl={105}
        bottomMd={170}
        bottomLg={120}
        bottomXl={80}
      >
    
      </Section>
      <Section>
        <AppointmentSectionStyle2
          bgUrl="/images/home_2/appointment_bg.svg"
          imgUrl="/images/home_1/dr.png"
          sectionTitle="Appointment"
          sectionTitleUp="BOOK AN"
        />
      </Section>
      <Section topMd={190} topLg={145} topXl={105}>
        <FaqSectionStyle2
          data={faqData}
          sectionTitle="Usually Asked"
          sectionTitleUp="What People"
        />
      </Section>
     
      <Section
        topMd={200}
        topLg={150}
        topXl={110}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
      
      </Section>
    </>
  );
}
